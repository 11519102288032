import axios from "axios"
import { toaster } from "baseui/toast"
import { NextApiResponse } from "next"

export function getErrorMessage(err: any) {
  if (axios.isAxiosError(err)) {
    return (
      err!.response?.data.userErrorMessage ||
      err!.response?.data.errorMessage ||
      "알 수 없는 오류 발생"
    )
  } else if (err instanceof Error) {
    return err.message
  } else {
    return "알 수 없는 오류 발생"
  }
}

export function getErrorCode(err: any) {
  if (axios.isAxiosError(err)) {
    return (err!.response?.data.errorCode as string) ?? undefined
  }
  return undefined
}

export function wrapErrorToNextApiResponse(res: NextApiResponse, err: any) {
  if (axios.isAxiosError(err)) {
    res.status(err.response?.status || 500).json({
      errorCode: err.response?.data.errorCode,
      userErrorMessage: err.response?.data.userErrorMessage,
      errorMessage: err.response?.data.errorMessage || "알 수 없는 오류 발생",
    })
  } else {
    res.status(500).json({
      userErrorMessage: "알 수 없는 오류 발생",
      errorMessage: "알 수 없는 오류 발생",
    })
  }
}

export type ToastConfig = {
  errorMessagePrefix?: string
  successMessage?: string
}

export async function wrapWithToast<T>(
  fn: () => Promise<T>,
  toastConfig: ToastConfig = {}
): Promise<T> {
  try {
    const res = await fn()
    if (toastConfig.successMessage) {
      toaster.positive(toastConfig.successMessage)
    }
    return res
  } catch (err) {
    if (axios.isAxiosError(err)) {
      const errMsgPrefix = toastConfig.errorMessagePrefix || ""
      toaster.negative(`${errMsgPrefix} ${getErrorMessage(err)}`, {
        autoHideDuration: 10000,
      })
    } else {
      toaster.negative(`${err}`, {
        autoHideDuration: 10000,
      })
    }
    throw err
  }
}
