import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react"
import { useTakerAuth, useSWRWithAuth } from "@today/api"
import { Client, PrepaidPoints } from "@today/api/taker"
import { LoadingOverlay } from "@today/ui/components/LoadingOverlay"
import { useAnonymousPermissionStatus, useUserInfo } from "@today/auth"

type PointsInfo = {
  points: PrepaidPoints
  isUsingPoints: boolean
  refreshPoints: () => Promise<void>
  hasNegativePoints: boolean
  hasNonPositivePoints: boolean
}

const PointsContext = createContext<PointsInfo>({
  points: {
    clientId: "",
    remainingPoints: 0,
  },
  isUsingPoints: false,
  hasNegativePoints: false,
  hasNonPositivePoints: false,
  refreshPoints: async () => {},
})

export const PointsProvider = ({ children }: PropsWithChildren<{}>) => {
  const { allowAnonymous } = useAnonymousPermissionStatus()
  const { clientId } = useUserInfo()
  const taker = useTakerAuth()

  const [client, setClient] = useState<Client>()

  useEffect(() => {
    if (!clientId) {
      setClient(undefined)
      return
    }
    ;(async () => {
      const client = await taker.retrieveClient(clientId)
      setClient(client)
    })()
  }, [clientId])

  const shouldUsePoints =
    !!client && client.enableBilling && client.billingType === "PREPAID"
  const { data: points, mutate } = useSWRWithAuth<PrepaidPoints>(
    shouldUsePoints && `/api/clients/${clientId}/prepaid-points`
  )
  const isLoading = (shouldUsePoints && !points) || (!allowAnonymous && !client)

  const pointsWithDefault = useMemo(
    () =>
      points ?? {
        clientId: clientId ?? "",
        remainingPoints: 0,
      },
    [clientId, points]
  )

  const refresh = useCallback(async () => {
    await mutate()
  }, [mutate])

  return (
    <PointsContext.Provider
      value={{
        points: pointsWithDefault,
        isUsingPoints: shouldUsePoints,
        refreshPoints: refresh,
        hasNegativePoints:
          shouldUsePoints && pointsWithDefault.remainingPoints < 0,
        hasNonPositivePoints:
          shouldUsePoints && pointsWithDefault.remainingPoints <= 0,
      }}
    >
      {isLoading ? <LoadingOverlay /> : children}
    </PointsContext.Provider>
  )
}

export function usePoints() {
  return useContext(PointsContext)
}
