import { useRouter } from "next/router"
import { MdHomeFilled } from "react-icons/md"
import { FaUser } from "react-icons/fa6"
import { useAuthentication, useUserInfo } from "@today/auth/keycloak"
import { defaultUserInfo, useKioskUserInfo } from "./KioskUserInfo"

export function NavBar() {
  const router = useRouter()
  const { userInfo, setUserInfo } = useKioskUserInfo()
  if (router.route === "/kiosk") {
    return <></>
  }
  return (
    <div className="flex flex-row items-center justify-between">
      <button
        className="flex items-center rounded-2xl border-2 border-gray-200 bg-gray-100 p-7 text-4xl font-bold hover:brightness-95"
        onClick={() => {
          router.back()
        }}
      >
        ← 뒤로가기
      </button>
      <button
        className="flex items-center rounded-2xl border-2 border-gray-200 bg-gray-100 p-7 text-4xl font-bold hover:brightness-95"
        onClick={() => {
          setUserInfo(null)
          router.push("/kiosk")
        }}
      >
        {userInfo.isAuthenticated ? (
          <>
            <FaUser />
            로그아웃
          </>
        ) : (
          <>
            <MdHomeFilled />
            처음으로
          </>
        )}
      </button>
    </div>
  )
}
