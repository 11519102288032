import {
  BulkCreateOrderResponse,
  Client,
  CreateOrderRequest,
  ListShippingPlacesResponse,
  ShippingPlace,
} from "@today/api/taker"
import { getErrorCode, getErrorMessage } from "@today/api/utils"
import { useSWRWithAuth, useTakerAuth } from "@today/api"
import { useUserInfo } from "@today/auth"
import { classNames, sleepAsync } from "@today/lib"
import { AxiosError } from "axios"
import { Banner } from "baseui/banner"
import { Button } from "baseui/button"
import {
  ColumnOptions,
  CustomColumn,
  StatefulDataTable,
} from "baseui/data-table"
import { Modal, ModalBody, ModalFooter, ModalHeader } from "baseui/modal"
import { ProgressBar } from "baseui/progress-bar"
import { Select } from "baseui/select"
import { toaster } from "baseui/toast"
import dayjs from "dayjs"
import ExcelJS from "exceljs"
import saveAs from "file-saver"
import { useRouter } from "next/router"
import { useCallback, useEffect, useMemo, useState } from "react"
import { useDropzone } from "react-dropzone"
import XLSX from "xlsx"
import { getShippingTypes, isLegacyClient } from "../../utils"
import getBinder from "../../utils/binder"
import {
  BaseRow,
  BaseRowWithIndex,
  Binder,
  Column,
  Group,
} from "../../utils/binder/common"
import { Input } from "baseui/input"
import { FaSpinner } from "react-icons/fa"
import { toInteger } from "lodash"
import {
  getPickUpTruckTypeCode,
  pickUpTruckTypes,
} from "../../utils/binder/truck-caller"
import { usePoints } from "../../providers/PointsProvider"
import { PointChargeNoticeModal } from "../points/PointChargeNoticeModal"

const BULK_REQUEST_SIZE_LIMIT = 50

export function ImportExcelPage() {
  const { clientId, clientRoles } = useUserInfo()
  const [rows, setRows] = useState<BaseRowWithIndex[]>([])

  // 페이지를 떠나 정보 유실되는 것 방지
  useEffect(() => {
    window.onbeforeunload = () => ""
    return () => {
      window.onbeforeunload = null
    }
  }, [])

  // 고객사 정보 조회
  const { data: client } = useSWRWithAuth<Client>(
    clientId && `/api/clients/${clientId}`
  )

  // 엑셀 바인더 설정
  const [binder, setBinder] = useState<Binder<any>>(getBinder(undefined))
  useEffect(() => {
    setBinder(getBinder(client, clientRoles))
  }, [client, clientRoles])

  return (
    <>
      {rows.length ? (
        <AfterUpload
          client={client}
          binder={binder}
          rows={rows}
          setRows={setRows}
        />
      ) : (
        <BeforeUpload binder={binder} onRowsParsed={setRows} />
      )}
    </>
  )
}

function BeforeUpload({
  binder,
  onRowsParsed,
}: {
  binder: Binder<any>
  onRowsParsed: (rows: BaseRowWithIndex[]) => void
}) {
  const { clientId } = useUserInfo()
  const [errors, setErrors] = useState<string[]>([])
  const [warnings, setWarnings] = useState<string[]>([])
  const handleExcelFile = useCallback(
    async (file: File) => {
      if (!clientId) return
      try {
        const workbook = XLSX.read(await file.arrayBuffer(), {
          cellDates: true,
        })
        const worksheet = workbook.Sheets[workbook.SheetNames[0]]
        const json = XLSX.utils.sheet_to_json<{ [key: string]: string }>(
          worksheet,
          { defval: "" }
        )
        const { rows, errors, warnings } = binder.parse(json)
        const rowsWithIndex = rows.map((v, i) => ({ ...v, index: i }))
        onRowsParsed(rowsWithIndex)
        setErrors(errors)
        setWarnings(warnings)
      } catch (error) {
        const errorMessage = `${error}`
        if (errorMessage.includes("password-protected")) {
          setErrors([
            "파일에 비밀번호가 걸려 있습니다. 비밀번호를 해제하고 업로드해주세요.",
          ])
        } else {
          setErrors([errorMessage])
        }
      }
    },
    [clientId, binder, onRowsParsed]
  )
  const { isDragAccept, getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      const file = acceptedFiles?.[0]
      if (file) {
        handleExcelFile(file)
      }
    },
  })
  return (
    <div className="mx-auto flex h-full max-w-6xl flex-col gap-y-8 overflow-y-scroll px-4 pb-16 pt-8 md:pt-12">
      <div className="text-3xl font-bold">엑셀로 배송 건 일괄 등록</div>
      <div
        className={classNames(
          "flex w-full cursor-pointer flex-col items-center justify-center gap-y-3 rounded-xl border-2 border-dashed py-8",
          isDragAccept
            ? "border-blue-500 bg-blue-100"
            : "border-gray-300 bg-gray-100"
        )}
        {...getRootProps()}
      >
        <input
          {...getInputProps()}
          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          multiple={false}
          disabled={!clientId}
        />
        <div className="text-gray-600">엑셀 파일을 여기에 끌어다 놓으세요</div>
        <Button>파일 찾기</Button>
      </div>
      {errors.length > 0 && (
        <div>
          <div className="mb-3 text-lg font-semibold">
            아래의 문제를 해결하고 다시 시도해 주세요.
          </div>
          <div className="flex flex-col gap-y-3 whitespace-pre-wrap">
            {errors.map((error) => (
              <Banner
                key={error}
                kind="negative"
                overrides={{
                  Root: {
                    style: {
                      margin: 0,
                      marginTop: 0,
                      marginBottom: 0,
                    },
                  },
                }}
              >
                {error}
              </Banner>
            ))}
          </div>
        </div>
      )}
      {errors.length > 0 && (
        <div>
          <div className="mb-3 text-lg font-semibold">
            아래의 문제를 확인해 주세요.
          </div>
          <div className="flex flex-col gap-y-3 whitespace-pre-wrap">
            {warnings.map((warning) => (
              <Banner
                key={warning}
                kind="warning"
                overrides={{
                  Root: {
                    style: {
                      margin: 0,
                      marginTop: 0,
                      marginBottom: 0,
                    },
                  },
                }}
              >
                {warning}
              </Banner>
            ))}
          </div>
        </div>
      )}
    </div>
  )
}

function AfterUpload({
  client,
  binder,
  rows,
  setRows,
}: {
  client?: Client
  binder: Binder<any>
  rows: BaseRowWithIndex[]
  setRows: (rows: BaseRowWithIndex[]) => void
}) {
  const router = useRouter()
  const { clientId, sellerName, clientRoles } = useUserInfo()
  const { isUsingPoints, points } = usePoints()
  const isTruckCaller = clientRoles.includes("order:truck-caller")
  const taker = useTakerAuth()
  const [isCreatingOrder, setCreatingOrder] = useState(false)
  const [creationProgress, setCreationProgress] = useState(5)
  const [errors, setErrors] = useState<string[]>([])
  const [senderAddress, setSenderAddress] = useState("")
  const [showPointsInfoModal, setShowPointsInfoModal] = useState(false)
  const [shippingFee, setShippingFee] = useState(0)

  // 출고지 목록 필요 시 조회
  const shippingPlaces = useShippingPlaces(clientId)
  const [shippingPlace, setShippingPlace] = useState<ShippingPlace>()
  useEffect(() => {
    if (shippingPlaces.length === 1) {
      setShippingPlace(shippingPlaces[0])
    }
  }, [shippingPlaces])

  // 테이블 뷰에서 수정 시 테이블 행 데이터에 반영
  const updateRow = useCallback(
    (row: BaseRowWithIndex, index: number) => {
      if (!rows) return
      const nextRows = [...rows]
      nextRows[nextRows.findIndex((v) => v.index === index)] = row
      binder.rows = nextRows
      setRows(nextRows)
    },
    [rows, binder, setRows]
  )

  // 데이터 validation
  const validateAndGetOrderRequests = useCallback(() => {
    if (!rows || !client) return

    // 요청 페이로드 생성 (합포장 그룹 단위로 묶음)
    let skipTakeOut = false
    let requests: CreateOrderRequest[] = []
    let createOrderErrors: string[] | undefined
    try {
      if (binder.shouldSupplyShippingPlace && !shippingPlace) {
        createOrderErrors = ["출고지를 선택해주세요."]
      } else {
        ;({
          skipTakeOut,
          requests,
          errors: createOrderErrors,
        } = binder.createOrderRequests(
          client,
          binder.shouldSupplyShippingPlace
            ? shippingPlace
            : shippingPlaces.length <= 1
            ? shippingPlaces[0]
            : shippingPlace ?? shippingPlaces,
          sellerName,
          senderAddress
        ))
      }
    } catch (e) {
      createOrderErrors = [`${e}`]
    }

    if (createOrderErrors?.length) {
      setErrors(createOrderErrors)
    }

    return {
      skipTakeOut,
      requests,
      createOrderErrors,
    }
  }, [
    binder,
    client,
    rows,
    sellerName,
    senderAddress,
    shippingPlace,
    shippingPlaces,
  ])

  useEffect(() => {
    if (!isTruckCaller) return
    if (!shippingPlace) return
    validateAndGetOrderRequests()
  }, [validateAndGetOrderRequests, isTruckCaller, shippingPlace])

  // 주문 등록 처리
  const createOrders = useCallback(async () => {
    if (!rows || !client) return

    // 요청 페이로드 생성 (합포장 그룹 단위로 묶음)
    const { skipTakeOut, requests, createOrderErrors } =
      validateAndGetOrderRequests()!

    if (createOrderErrors?.length) return

    if (!binder.groups) return

    // 운송 요금 검사
    if (isUsingPoints && clientId) {
      const incheon24 = client.conditions.some((c) =>
        c.deliveryClasses.includes("INCHEON_24")
      )
      const { shippingFees } = await taker.bulkExpectShippingFees(
        clientId,
        incheon24 ? "INCHEON_24" : "INCHEON_48",
        requests.map((request) => ({
          fresh: request.physicalAttributes?.fresh,
          size: request.physicalAttributes?.size,
        }))
      )
      const fee = shippingFees.reduce((acc, n) => acc + n, 0)
      if (fee > points.remainingPoints) {
        setShippingFee(fee)
        // TODO 선결제
        //setShowPointsInfoModal(true)
        //return
      }
      if (
        !confirm(
          // TODO 선결제
          //`${fee.toLocaleString()}원이 포인트에서 차감됩니다. 주문을 진행할까요?`
          `포인트가 차감됩니다. 주문을 진행할까요?`
        )
      ) {
        return
      }
    }

    setCreatingOrder(true)

    // 일괄 요청 단위로 분리
    const bulkRequests = []
    while (!!requests.length) {
      bulkRequests.push(requests.splice(0, BULK_REQUEST_SIZE_LIMIT))
    }

    // 주문 일괄 등록 API 병렬 호출
    const results: BulkCreateOrderResponse[] = []
    for (const orders of bulkRequests) {
      orders.forEach((order) => {
        if (order.sender?.address)
          order.sender.address = order.sender.address.trim()
        if (order.receiver?.address)
          order.receiver.address = order.receiver?.address?.trim()
      })
      const result = await taker
        .bulkCreateOrder(client.clientId, {
          skipTakeOut,
          orders,
        })
        .catch((err: AxiosError) => {
          if (getErrorCode(err) === "E20696") {
            setShippingFee(0)
            setShowPointsInfoModal(true)
          }
          return {
            orders: [],
            failedOrders: orders.map((v, i) => ({
              index: i,
              reason: getErrorMessage(err),
            })),
          } as BulkCreateOrderResponse
        })
      // 각 건 끝날 때마다 progress bar 업데이트
      setCreationProgress((p) => p + 95 / bulkRequests.length)
      results.push(result)
    }

    // 실패 요청 추출 및 재인덱싱
    const failedOrders = results
      .flatMap((v, i) =>
        v.failedOrders?.map((failedOrder) => ({
          ...failedOrder,
          index: failedOrder.index + i * BULK_REQUEST_SIZE_LIMIT,
        }))
      )
      .filter((v) => !!v)

    // 재등록 시도를 위해 생성에 실패한 합포장 그룹에 속한 테이블 행들을 필터링
    const failedRowsWithErrMessage = failedOrders
      .map((failure) => ({
        rowIndices: binder.groups![failure!.index].indices,
        errMessage: failure!.reason,
      }))
      .reduce<{ index: number; errMessages: string[] }[]>(
        (acc, { rowIndices, errMessage }) => {
          rowIndices.forEach((index) => {
            const row = acc.find((v) => v.index === index)
            if (row) {
              row.errMessages.push(errMessage)
            } else {
              acc.push({ index, errMessages: [errMessage] })
            }
          })
          return acc
        },
        []
      )
      .sort((a, b) => a.index - b.index)
      .map(({ index, errMessages }) => ({ row: rows[index], errMessages }))

    // 프로그레스바가 완료되었음을 1초 정도 보여준다.
    await sleepAsync(1000)

    // 등록 결과 리포트
    const failedRows = failedRowsWithErrMessage.map(({ row }) => row)
    const orderErrors: string[] = []
    for (const rowWithMessage of failedRowsWithErrMessage) {
      const { row, errMessages } = rowWithMessage
      orderErrors.push(`${row.index + 1}번째 줄:\n` + errMessages.join("\n"))
    }
    setErrors(orderErrors)
    setCreatingOrder(false)
    if (failedRows.length) {
      toaster.negative(
        <>
          <p>{rows.length - failedRows.length}건 등록 완료되었습니다.</p>
          <p>실패한 {failedRows.length} 건을 수정 후 재등록해주세요.</p>
        </>,
        {}
      )
    } else {
      toaster.positive(<>등록이 모두 완료되었습니다.</>, {})
      await router.push("/orders")
      return
    }

    // 실패건만 추려 다시 재시도
    binder.resetWithRows(failedRows)
    setRows(failedRows)
  }, [
    rows,
    client,
    validateAndGetOrderRequests,
    binder,
    setRows,
    taker,
    router,
  ])

  const downloadRows = async () => {
    if (!rows) return
    const workbook = new ExcelJS.Workbook()
    const sheet = workbook.addWorksheet()
    sheet.addRow(binder.columns.map((c) => c.label))
    for (const row of rows) {
      sheet.addRow(
        binder.columns.map((c: Column<BaseRowWithIndex>) => row[c.key])
      )
    }
    const buffer = await workbook.xlsx.writeBuffer()
    saveAs(new Blob([buffer]), `orders-${dayjs().format("YYYY-MM-DD")}.xlsx`)
  }

  const supportedShippingTypes = getShippingTypes(client)
  const columns: ColumnOptions[] = useMemo(
    () => [
      CustomColumn({
        title: "연번",
        mapDataToValue: ({ index }: { index: number }) => index + 1,
        renderCell: (props: any) => (
          <div className="flex h-full w-full items-center justify-center font-semibold">
            {props.value}
          </div>
        ),
      }),
      ...binder.columns
        .filter((column) => {
          // 운송 타입에 따라서 출고지 정보 수정을 하지 못하게 함
          const key = column.key as string
          if (isLegacyClient(client)) return true
          const [showSenderSP, showReceiverSP] = supportedShippingTypes.reduce(
            ([s, r], t) => {
              switch (t) {
                case "STATION_TO_LM":
                  return [true, r]
                case "LM_TO_STATION":
                  return [s, true]
                case "STATION_TO_STATION":
                  return [true, true]
                case "LM_TO_LM":
                  return [s, r]
              }
            },
            [false, false]
          )
          if (key === "senderShippingPlaceName") return showSenderSP
          if (key === "receiverShippingPlaceName") return showReceiverSP
          return true
        })
        // 옵셔널 컬럼 중 값이 채워지지 않은 컬럼은 표시 제외
        .filter(
          (column) =>
            !column.optional ||
            !!rows?.find((row) => !!row[column.key as string])
        )
        .map((column) =>
          CustomColumn({
            title: Array.isArray(column.label) ? column.label[0] : column.label,
            mapDataToValue: (row: BaseRowWithIndex) => [
              row[column.key as string],
              (value: string) => {
                updateRow({ ...row, [column.key as string]: value }, row.index)
              },
            ],
            renderCell: function Cell(props: any) {
              const [value, setValue] = props.value
              const [text, setText] = useState(value)
              return (
                <input
                  className="h-full w-full bg-transparent px-2 py-4 text-base"
                  style={{ minWidth: Math.min((value?.length ?? 0) * 10, 300) }}
                  value={text}
                  onChange={(event) => setText(event.target.value)}
                  onBlur={() => setValue(text)}
                />
              )
            },
          })
        ),
    ],
    [binder.columns, rows, supportedShippingTypes, updateRow, client]
  )

  const showsShippingPlaceSelector =
    // legacy 화주인 경우
    (binder.shouldSupplyShippingPlace && shippingPlaces.length > 1) ||
    // ST->LM, LM->LM 운송 타입을 둘 다 가진 경우
    (supportedShippingTypes.includes("STATION_TO_LM") &&
      supportedShippingTypes.includes("LM_TO_LM")) ||
    // ST-LM 타입만 가지고 있고 출고지가 2개 이상이며 엑셀 파일에 출고지 정보가 없는 경우
    (supportedShippingTypes.includes("STATION_TO_LM") &&
      shippingPlaces.length > 1 &&
      rows.every((row) => !row["senderShippingPlaceName"]))
  const showsSenderAddressInput =
    supportedShippingTypes.includes("LM_TO_LM") &&
    rows.every((row) => !row["senderAddress"])

  // 예상 운송비 계산
  const { rowsWithShippingFee, isShippingFeeCalculated } =
    useEstimatedShippingFee(
      client,
      isTruckCaller,
      shippingPlace,
      rows,
      binder.groups
    )

  const columnsWithShippingFees = useMemo(
    () => [
      ...columns,
      ...(isShippingFeeCalculated
        ? [
            CustomColumn({
              title: "예상 운송비",
              mapDataToValue: (row) => row.shippingFee,
              renderCell: (props: any) => (
                <div className="flex h-full w-full items-center justify-center">
                  {props ? (
                    props.value ? (
                      <>{(props.value as number).toLocaleString()}원</>
                    ) : (
                      <></>
                    )
                  ) : (
                    <FaSpinner className="mb-[2px] inline-block animate-spin" />
                  )}
                </div>
              ),
            }),
          ]
        : []),
    ],
    [columns, isShippingFeeCalculated]
  )

  return (
    <>
      <div className="flex h-full flex-col">
        <div className="flex flex-1 overflow-hidden">
          <div className="flex flex-1 flex-col">
            <div className="-mb-1 flex flex-row justify-between px-4 pt-2 font-semibold">
              <div>칸을 클릭하면 값을 수정할 수 있습니다.</div>
              {isShippingFeeCalculated && (
                <div>
                  총 예상 운송비:{" "}
                  {rowsWithShippingFee
                    .reduce(
                      (acc, { shippingFee }) => acc + (shippingFee ?? 0),
                      0
                    )
                    .toLocaleString()}
                  원
                </div>
              )}
            </div>
            <div className="relative -mb-1 flex-1">
              <StatefulDataTable
                columns={columnsWithShippingFees}
                rows={rowsWithShippingFee.map((row, index) => ({
                  id: index,
                  data: row,
                }))}
                resizableColumnWidths
                searchable={false}
                filterable={false}
                rowHeight={48}
              />
              <div className="absolute right-0 top-0 h-full w-6 bg-gradient-to-r from-transparent to-white" />
            </div>
          </div>
          <div
            className={classNames(
              "flex h-full w-80 max-w-[25%] flex-col gap-y-3 overflow-y-scroll whitespace-pre-wrap border-l border-gray-200 p-2",
              errors.length ? "" : "hidden"
            )}
          >
            <div className="-mb-2 p-2 text-lg font-semibold">
              문제 해결 필요
            </div>
            {errors.map((error) => (
              <div className="break-words rounded-xl bg-red-100 p-4">
                {error}
              </div>
            ))}
          </div>
        </div>
        <div className="flex items-center justify-end gap-x-2 border-t border-gray-200 p-4">
          {showsShippingPlaceSelector && (
            <div className="w-56 min-w-fit">
              <Select
                value={shippingPlace && [shippingPlace]}
                onChange={({ value }) =>
                  setShippingPlace(value[0] as ShippingPlace)
                }
                options={shippingPlaces}
                valueKey="id"
                labelKey="name"
                placeholder="선택..."
              />
            </div>
          )}
          {showsSenderAddressInput &&
            !rows[0]["senderAddress"] &&
            !rows[0]["senderShippingPlaceName"] && (
              <div className="w-64">
                <Input
                  placeholder={
                    client?.role === "SME"
                      ? "픽업지 주소 일괄 적용"
                      : "보낸이 주소 일괄 적용"
                  }
                  value={senderAddress}
                  onChange={(e) => setSenderAddress(e.target.value)}
                />
              </div>
            )}
          <Button
            kind="tertiary"
            onClick={() => {
              const confirmed = confirm(
                "현재 표시된 데이터는 삭제됩니다. 계속하시겠어요?"
              )
              if (confirmed) {
                setRows([])
              }
            }}
          >
            파일 다시 업로드
          </Button>
          <Button kind="secondary" onClick={downloadRows}>
            남은 데이터 다운로드
          </Button>
          <Button onClick={createOrders}>{rows.length}건 일괄 등록</Button>
        </div>
      </div>
      <Modal isOpen={isCreatingOrder} closeable={false}>
        <ModalHeader>등록 중...</ModalHeader>
        <ModalBody>
          <div className="flex justify-center">
            <ProgressBar value={creationProgress} />
          </div>
        </ModalBody>
        <ModalFooter />
      </Modal>
      <PointChargeNoticeModal
        open={showPointsInfoModal}
        fee={shippingFee}
        onClose={() => {
          setShowPointsInfoModal(false)
          setShippingFee(0)
        }}
      />
    </>
  )
}

function useShippingPlaces(clientId?: string): ShippingPlace[] {
  const { data: shippingPlacesData } =
    useSWRWithAuth<ListShippingPlacesResponse>(
      clientId && `/api/clients/${clientId}/shipping-places`
    )
  const shippingPlaces = useMemo(
    () =>
      shippingPlacesData?.shippingPlaces.sort((sp1, sp2) =>
        sp1.name.localeCompare(sp2.name)
      ) ?? [],
    [shippingPlacesData]
  )
  return shippingPlaces
}

function useEstimatedShippingFee(
  client: Client | undefined,
  isTruckCaller: boolean,
  shippingPlace: ShippingPlace | undefined,
  rows: BaseRowWithIndex[],
  groups: Group<BaseRow>[] | null
) {
  const taker = useTakerAuth()
  const [rowsWithShippingFee, setRowsWithShippingFee] =
    useState<(BaseRowWithIndex & { shippingFee?: number })[]>(rows)
  const [isShippingFeeCalculated, setShippingFeeCalculated] = useState(false)
  useEffect(() => {
    if (!client || !isTruckCaller || !shippingPlace || !groups) return
    const deliveryClass = client.conditions
      .find((condition) =>
        condition.deliveryClasses.some((dc) => dc.startsWith("TOGETHER_"))
      )
      ?.deliveryClasses.find((dc) => dc.startsWith("TOGETHER_"))
    if (!deliveryClass) return

    // 합포장으로 인한 대표 rows 선별
    const indices = new Set<number>()
    groups.forEach((group) => {
      const [pickUpTruckType, pickUpTruckCount] = group.rows
        .filter(
          ({ pickUpTruckType, pickUpTruckCount }) =>
            pickUpTruckType.trim() !== "" && parseInt(pickUpTruckCount) > 0
        )
        .map<[string, number]>(({ pickUpTruckType, pickUpTruckCount }) => [
          pickUpTruckType,
          parseInt(pickUpTruckCount),
        ])
        .reduce(
          ([prevType, prevCount], [type, count]) => {
            if (count > prevCount) return [type, count]
            if (count < prevCount) return [prevType, prevCount]
            if (
              pickUpTruckTypes.findIndex((v) => v === type) >
              pickUpTruckTypes.findIndex((v) => v === prevType)
            )
              return [type, count]
            return [prevType, prevCount]
          },
          ["", 0]
        )
      const idx = group.rows.findIndex(
        (row) =>
          row.pickUpTruckType === pickUpTruckType &&
          parseInt(row.pickUpTruckCount) === pickUpTruckCount
      )
      indices.add(group.indices[idx])
    })

    let shippingFeeIndex = 0
    const normalizedRows: {
      row: BaseRowWithIndex
      shippingFeeIndex?: number
    }[] = []
    for (const [i, row] of rows.entries()) {
      if (indices.has(i)) {
        normalizedRows.push({ row, shippingFeeIndex: shippingFeeIndex })
        shippingFeeIndex++
      } else {
        normalizedRows.push({ row })
      }
    }

    ;(async () => {
      const { shippingFees } = await taker.bulkExpectShippingFees(
        client.clientId,
        deliveryClass,
        normalizedRows
          .filter(({ shippingFeeIndex }) => shippingFeeIndex !== undefined)
          .map(({ row }) => {
            const r = row as any
            return {
              senderAddress: `${shippingPlace.addressInfo.regionBaseAddress} ${shippingPlace.addressInfo.regionDetailAddress}`,
              receiverAddress: r["receiverAddress"],
              pickUpTrucks: [
                {
                  type: getPickUpTruckTypeCode(r["pickUpTruckType"]),
                  count: toInteger(r["pickUpTruckCount"]),
                },
              ],
            }
          })
      )
      setRowsWithShippingFee(
        normalizedRows.map(({ row, shippingFeeIndex }) => {
          if (shippingFeeIndex !== undefined) {
            return {
              ...row,
              shippingFee: shippingFees[shippingFeeIndex],
            } as BaseRowWithIndex & { shippingFee?: number }
          }
          return {
            ...row,
            shippingFee: undefined,
          } as BaseRowWithIndex & { shippingFee?: number }
        })
      )
      setShippingFeeCalculated(true)
    })()
  }, [taker, rows, client, isTruckCaller, shippingPlace, groups])

  return {
    rowsWithShippingFee,
    isShippingFeeCalculated,
  }
}
