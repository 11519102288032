import { PropsWithChildren } from "react"

export function Button({
  disabled = false,
  color = "blue",
  children,
  onClick,
}: PropsWithChildren<{
  disabled?: boolean
  color?: "red" | "blue" | "white"
  onClick?: () => void
}>) {
  let colorClass = {}
  if (color === "blue") {
    colorClass = {
      backgroundColor: "#00BCFF",
    }
  } else if (color === "red") {
    colorClass = {
      backgroundColor: "#FF4A4A",
    }
  } else if (color === "white") {
    colorClass = {
      backgroundColor: "#FFFFFF",
    }
  }

  return (
    <button
      disabled={disabled}
      style={{ height: "160px", ...(disabled ? {} : colorClass) }}
      className="w-full rounded-2xl px-20 text-5xl font-bold leading-tight text-white hover:brightness-95 disabled:bg-gray-300"
      onClick={onClick}
    >
      {children}
    </button>
  )
}
